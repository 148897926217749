<template>
  <publicExport :exportTypeList="exportTypeList"></publicExport>
</template>
<script>
import { alias689d3582fa8c4a7891f9d6ea8bcd03a9 } from '@/customFolder/customVueAlias.js';

import publicExport from "@/components/common/publicExport";
import {setConfigCharacters} from "@/utils/common";

export default {
  name: "exportTask",
  data() {
    return {
      exportTypeList: [
        {value: 'productExport', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000685')},
        {value: 'inventoryExport', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000717')},
        {value: 'inventoryLogExport', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005623')},
        {value: 'packageExport', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002525')},
        {value: 'ymsInventoryExport', name: `${setConfigCharacters}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005622')}`},
        {value: 'supplierBillExport', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005624')},
        {value: 'wmsPickupOrderExport', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005625')},
        {value: 'packageInfoOrderExport', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001384')},
        {value: 'packageInfoStockExport', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001282')},
      ]
    };
  },
  components: {
    publicExport
  }
};
</script>

<style scoped>

</style>
